import styled, { css } from "styled-components";
import { ParagraphL } from "../../../ui/text/ParagraphComponents";

export const AddressSectionWrapper = styled.section`
    padding-top: 33rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 64rem;

    @media ${({ theme }) => theme.media.medium} {  
        padding-top: 16px;
        gap: 48px;
    }
`;

export const AddressInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16rem;

    @media ${({ theme }) => theme.media.medium} {
        align-items: flex-start;
        gap: 16px;
    }
`;

export const AddressText = styled(ParagraphL)<{isUnderline?: boolean}>`
    text-align: end;
    text-decoration: ${(props) => props.isUnderline ? "underline" : "none"};

    @media ${({ theme }) => theme.media.medium} {
        text-align: start;

        br {
            display: none;
        }
    }
    
    @media ${({ theme }) => theme.media.small} {
        br {
            display: inline;
        }
    }
`;

export const StyledLink = styled.a`
    font-size: 21rem;
    font-weight: 400;
    color: ${({ theme}) => theme.colors.white};

    line-height: 140%;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            font-size: 21px;
        }
    `};
    text-decoration: underline;
`;