import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { languages, supportedLanguages } from "./constants/languages";

import en from "./resources/locales/en.json";
import ru from "./resources/locales/ru.json";

const resources = {
    [languages.en]: { translation: en },
    [languages.ru]: { translation: ru },
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        resources,
        fallbackLng: "en",
        supportedLngs: supportedLanguages,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
