import React, { useEffect, useMemo, useState } from "react";
import ReactDom from "react-dom";
import type YandexMapsType from "@yandex/ymaps3-types/reactify/index"
import { useTranslation } from "react-i18next";
import { SupportedLanguage, yandexLocales } from "../../../../constants/languages";

const _env = window.config || process.env;
const yandexMapsKey = _env.REACT_APP_YANDEX_MAPS_KEY;
const yandexMapsUrlWithoutLanguage = `https://api-maps.yandex.ru/v3/?apikey=${yandexMapsKey}`;

const isYandexMapScriptLoaded = () =>
    document.querySelectorAll(`[src^="${yandexMapsUrlWithoutLanguage}"]`).length > 0

const useYandexMapsScript = () => {
    const { i18n } = useTranslation();
    const [yandexMaps, setYandexMaps] = useState<null | typeof YandexMapsType>(null)
    const currentLanguage = yandexLocales[i18n.language as SupportedLanguage]
    const yandexMapsUrl = `${yandexMapsUrlWithoutLanguage}&lang=${currentLanguage}`
    const [currentYandexMapsUrl, setCurrentYandexMapsUrl] = useState(yandexMapsUrl)

    const initializeYandexMaps = async () => {
        if(!yandexMaps) {
            const [ymaps3React] = await Promise.all([ymaps3?.import("@yandex/ymaps3-reactify"), ymaps3?.ready]);
            setYandexMaps(ymaps3React);
        }
    }

    const createYandexMapsScript = () => {
        const script = document.createElement("script");
        script.src = yandexMapsUrl;
        script.type= "text/javascript";
        script.onload = async () => {
            initializeYandexMaps();
        }
        setCurrentYandexMapsUrl(yandexMapsUrl)
        document.head.appendChild(script);
    }

    useEffect(() => {
        if(isYandexMapScriptLoaded()) {
            if(currentYandexMapsUrl === yandexMapsUrl) {
                initializeYandexMaps();
            } else {
                window.location.reload();
            }
            return;
        }

        if (!yandexMapsKey ) {
            console.error("REACT_APP_YANDEX_MAPS_KEY is not defined in environment variables.");
            return;
        }

        createYandexMapsScript()
    }, [yandexMapsUrl]);

    const yandexMapsData = useMemo(() => {
        if(yandexMaps) {
            const reactify = yandexMaps.reactify.bindTo(React, ReactDom);
            const {YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker} = reactify.module(ymaps3);
            return { reactify, YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker };
        }
        return {};
    }, [yandexMaps])

    return yandexMapsData;
};

export default useYandexMapsScript;