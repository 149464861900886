import styled from "styled-components";
import { createElement } from "react";

// @ts-expect-error Yandex Map typing problem
export const StyledYandexMaps = styled(({tag, children, ...props}) => createElement(tag, props, children))`
    border-radius: 12px;
    visibility: ${({ visible }) => visible ? "visible" : "hidden"};
    height: 445rem !important;

    @media ${({ theme }) => theme.media.medium} {
        height: 343px !important;
    }
`