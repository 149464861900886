import type { VectorCustomizationItem, LngLat } from "ymaps3";
import useYandexMapsScript from "../YandexMapsScript/YandexMapsScript";
import { customSchemeStyle, LOCATION } from "./constants";
import { ReactComponent as YandexMarker} from "../../../../resources/icon/yandex-marker.svg";
import { StyledYandexMaps } from "./YandexMapsComponents";
import { useEffect, useState } from "react";

const YandexMaps = () => {
    const { reactify, YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker } = useYandexMapsScript();
    const [isYandexMapsVisible, setIsYandexMapsVisible] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setIsYandexMapsVisible(true);
        }, 1000)
    },[])

    if (!YMap || !YMapDefaultSchemeLayer || !YMapDefaultFeaturesLayer || !YMapMarker) return null;

    return (
        <StyledYandexMaps tag={YMap} location={reactify.useDefault(LOCATION)} visible={isYandexMapsVisible}>
                <YMapDefaultSchemeLayer customization={customSchemeStyle as VectorCustomizationItem[]} />
                <YMapDefaultFeaturesLayer />
                <YMapMarker coordinates={reactify.useDefault(LOCATION.center as LngLat)}>
                    <YandexMarker />
                </YMapMarker>
        </StyledYandexMaps>
    );
};

export default YandexMaps;
