export const languages = {
    ru: "ru",
    en: "en"
} as const;

export const yandexLocales = {
    ru: "ru_RU",
    en: "en_RU"
} as const;

type SupportedLanguageKeys = keyof typeof languages;
export type SupportedLanguage = typeof languages[SupportedLanguageKeys];

export const supportedLanguages: SupportedLanguage[] = Object.values(languages);
